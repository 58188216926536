import * as React from "react";
import nps_scores from "../../data/nps_scores.json";
import NpsRange from "../NpsRange/NpsRange";
import CtaButton from "../CtaButton/CtaButton";
import { npsScoreColor } from "../../utilities/colors";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BubbleController,
  PointElement,
  LineElement,
  LineController,
  Filler,
} from "chart.js";

import {
  chartSliderContainer,
  chartContainer,
  chartCta,
  chartGraph,
  sliderContainer,
  emojiStyle,
} from "./NpsChart.module.scss";

Chart.register(
  CategoryScale,
  LinearScale,
  BubbleController,
  PointElement,
  LineElement,
  LineController,
  Filler
);

export default function NpsChart({
  rangeValue,
  setRangeValue,
  initChartScore,
}) {
  // init useRef to store the chart which prevents rerender unless no chart exists. chart/setChart allows updates to the chart via the slider.
  const [chart, setChart] = React.useState(null);
  const chartRef = React.useRef(null);
  const percentile = Math.round(nps_scores[rangeValue].percentile);

  //creates a new chart, returns nothing if the chart exists

  React.useEffect(() => {
    if (!chartRef) return;
    const chartCanvas = chartRef.current;
    const ctx = chartCanvas.getContext("2d");

    const gradient = ctx.createLinearGradient(0, 0, 0, 200);

    gradient.addColorStop(0, "rgba(0, 105, 180, 0.25)");
    gradient.addColorStop(0.5, "rgba(0, 105, 180, 0.125)");
    gradient.addColorStop(1, "rgba(0, 105, 180, 0)");

    //InitChartScore is used to create the chart w/o the need for an additional dependency since the chart should only create once and not rely on the slider's value state

    const initScore = initChartScore.current;
    const percent = Math.round(nps_scores[initScore].percentile);

    //creates the data for the chart based on the nps_scores.json in src/data

    let mapped_data = Object.entries(nps_scores)
      .map(([score, { percentile }]) => ({
        x: parseInt(score, 10),
        y: percentile,
      }))
      .sort((a, b) => a.x - b.x);

    //creates the point with styling

    let pointColor = npsScoreColor(initScore).hex,
      data = {
        datasets: [
          {
            animation: false,
            backgroundColor: pointColor,
            borderColor: "rgba(255,255,255,0.75)",
            borderWidth: 7,
            radius: 7,
            hoverRadius: 3.5,
            hoverBackgroundColor: pointColor,
            type: "bubble",
            minRotation: 0,
            maxRotation: 0,
            data: [
              {
                y: percent,
                x: initScore,
              },
            ],
          },
          {
            fill: true,
            backgroundColor: gradient,
            pointRadius: 0,
            borderWidth: 3,
            borderColor: "#0069B4",
            data: mapped_data,
          },
        ],
      };

    //provides an object with options for chart creation https://www.chartjs.org/docs/latest/general/data-structures.html

    var options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "linear",
          title: {
            display: true,
            text: "Net Promoter Score",
            color: "#054372",
            font: {
              size: 16,
              weight: 700,
              family: '"Open Sans", "Segoe UI", Tahoma, sans-serif',
            },
          },
          min: 30,
          max: 100,
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: true,
            text: "Percentile rank",
            color: "#054372",
            font: {
              size: 16,
              weight: 700,
              family: '"Open Sans", "Segoe UI", Tahoma, sans-serif',
            },
          },
          grid: {
            color: "#ECF2F9",
            lineWidth: 1,
          },
          ticks: {
            stepSize: 10,
            callback: (value) => value + "%",
          },
        },
      },
      elements: {
        line: {
          cubicInterpolationMode: "monotone",
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };

    const chart = new Chart(ctx, {
      type: "line",
      data: data,
      options: options,
    });
    setChart(chart);
  }, [chartRef, initChartScore]);

  // Triggers chart update when score is changed via the slider

  React.useEffect(() => {
    let scoreColor = npsScoreColor(rangeValue).hex;

    if (chart) {
      let dataset = chart.data.datasets[0];

      dataset.data[0] = {
        x: rangeValue,
        y: nps_scores[rangeValue].percentile,
      };

      dataset.backgroundColor = scoreColor;
      dataset.hoverBackgroundColor = scoreColor;

      chart.update({
        duration: 0,
      });
    }
  }, [rangeValue, chart]);

  //returns an ordinal based NPS passed

  function ordinal(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  }

  //returns an emoji and link based on NPS passed

  function npsPercentileDescription(score) {
    const l_improve = `/tips/nps`;
    const improve_button_text = `Read our guide to improve your NPS`;

    const l_promoter = `/tips/promoters`;
    const promoter_button_text = `Read our promoters guide`;

    switch (true) {
      case score < 60:
        return {
          emoji: "🙁",
          link: l_improve,
          buttonText: improve_button_text,
          description: "We can help you improve your score:",
          ordinal: ordinal(percentile),
        };
      case score < 71:
        return {
          emoji: "😐",
          link: l_improve,
          buttonText: improve_button_text,
          description: "We can help you improve your score:",
          ordinal: ordinal(percentile),
        };
      case score < 80:
        return {
          emoji: "🙂",
          link: l_improve,
          buttonText: improve_button_text,
          description: "We can help you improve your score:",
          ordinal: ordinal(percentile),
        };
      case score < 90:
        return {
          emoji: "😄",
          link: l_promoter,
          buttonText: promoter_button_text,
          description: "Wow! Now, get the most from your promoters:",
          ordinal: ordinal(percentile),
        };
      default:
        return {
          emoji: "😁",
          link: l_promoter,
          buttonText: promoter_button_text,
          description: "Wow! Now, get the most from your promoters:",
          ordinal: ordinal(percentile),
        };
    }
  }

  return (
    <div className={chartSliderContainer}>
      <div className={sliderContainer}>
        <NpsRange rangeValue={rangeValue} setRangeValue={setRangeValue} />
      </div>
      <div className={chartContainer}>
        <div className={chartCta}>
          <span className={emojiStyle}>
            {npsPercentileDescription(rangeValue).emoji}
          </span>
          <h2>
            {percentile}
            <sup>{npsPercentileDescription(rangeValue).ordinal}</sup> percentile
          </h2>
          <p>{npsPercentileDescription(rangeValue).description}</p>
          <CtaButton
            name={npsPercentileDescription(rangeValue).buttonText}
            link={npsPercentileDescription(rangeValue).link}
          />
        </div>
        <div className={chartGraph}>
          <canvas id="chart" ref={chartRef} />
        </div>
      </div>
    </div>
  );
}
