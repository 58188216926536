import * as React from "react";
import ScoreComparisonCard from "../ScoreComparisonCard/ScoreComparisonCard";
import { useStaticQuery, graphql } from "gatsby";
import {
  scoreComparisonContainer,
  agentScore,
} from "./ScoreComparison.module.scss";

/**
 * This is the container section for the score comparison section on Indium and compare your NPS.
 *
 * This can be used as many times as we want. The data is currently being passed from a JSON file, but we can
 * easily replicate this in Strapi.
 *
 * This returns an array/list of the <ScoreComparisonCards /> component
 */

export default function ScoreComparison({ children }) {
  const query = useStaticQuery(graphql`
    query industryNPS {
      allStrapiIndustries {
        nodes {
          name
          nps
          strapiId
        }
      }
    }
  `);

  const industries = query.allStrapiIndustries.nodes;

  return (
    <div className={scoreComparisonContainer}>
      <h2>How does your score compare to other industries?</h2>
      <p>
        Insurance agencies typically score higher than other industries,
        including insurance carriers.
      </p>
      {children && <div className={agentScore}>{children}</div>}

      {industries.map((industry, i) => {
        return (
          <ScoreComparisonCard
            key={`${industry.name}-${i}`}
            name={industry.name}
            nps={industry.nps}
          />
        );
      })}
    </div>
  );
}
