import * as React from "react";
import {
  cardContainer,
  cardContentContainer,
  cardShadow,
} from "./ScoreComparisonCard.module.scss";

/**
 *
 * This returns the ScoreComparisonCard component.
 *
 * We're currently passing this data from a JSON file, but can easily source it from Strapi.
 *
 * We only need two fields the name of the industry and the NPS. The switch statement within
 * the component will do the work of figuring out what emoji and coloring to use.
 *
 * This allows for additional industries to be added or nps changed.
 *
 * */

export default function ScoreComparisonCard({ name, nps }) {
  let emoji;
  let color;

  switch (true) {
    case nps > 70:
      emoji = "😁";
      color = "#38b1b3";
      break;
    case nps >= 50 && nps <= 70:
      emoji = "🙂";
      color = "#0069b4";
      break;
    case nps >= 31 && nps < 50:
      emoji = "😐";
      color = "#efb800";
      break;
    case nps < 31:
      emoji = "🙁";
      color = "#ea444d";
      break;
    default:
      emoji = "default";
      color = "#000";
  }

  return (
    <div className={cardContainer}>
      <div className={cardShadow}>
        <div className={cardContentContainer} style={{ textAlign: "center" }}>
          <div>
            <span style={{ fontSize: "3.5rem" }}>{emoji}</span>
            <h6>{name}</h6>
            <h4 style={{ color }}>{nps}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
