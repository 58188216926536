import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../Layouts/Layout/Layout";
import Seo from "../components/Seo/Seo";
import RocketReferralsCta from "../components/RocketReferralsCta/RocketReferralsCta";
import ScoreComparison from "../components/ScoreComparison/ScoreComparison";
import NpsChart from "../components/NpsChart/NpsChart";
import { npsScoreColor } from "../utilities/colors";

import {
  whiteBackground,
  textCenter,
  titleContainer,
  agentScoreDisplay,
} from "../styles/landing.module.scss";
import {
  sky,
  cloud,
  mountainRight,
  mountainLeft,
} from "../styles/design.module.scss";

const FindYourRank = () => {
  const initChartScore = React.useRef(72);
  const [rangeValue, setRangeValue] = React.useState(initChartScore.current);

  return (
    <Layout>
      <Seo title="Find Your Rank" />
      <div
        style={{
          position: "relative",
          marginTop: "-4.5rem",
          paddingTop: "4.5rem",
        }}
      >
        <div className={sky}>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <section className={`wrapper ${textCenter} ${titleContainer}`}>
          <h1>
            How does your NPS<sup>®</sup> rank?
          </h1>
          <h3>Compare your score with agencies using Rocket Referrals.</h3>
        </section>
      </div>
      <div className={whiteBackground}>
        <section className="wrapper">
          <NpsChart
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
            initChartScore={initChartScore}
          />
        </section>
      </div>
      <section className="wrapper">
        <ScoreComparison>
          <div
            style={{
              backgroundColor: npsScoreColor(rangeValue).hex,
            }}
            className={agentScoreDisplay}
          >
            {`Your score: ${rangeValue}`}
          </div>
        </ScoreComparison>
      </section>
      <div style={{ position: "relative" }}>
        <div className={sky}>
          <div
            className="wrapper"
            style={{ position: "relative", zIndex: "1500", height: "100%" }}
          >
            <StaticImage
              className={mountainLeft}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../images/mountain_left.svg"
              alt=""
            />
            <StaticImage
              className={mountainRight}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../images/mountain_right.svg"
              alt=""
            />
          </div>

          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <RocketReferralsCta
          h2="We can make NPS work for you."
          buttonText="Check out Rocket Referrals"
          link="https://www.rocketreferrals.com/features"
        />
      </div>
    </Layout>
  );
};

export default FindYourRank;
