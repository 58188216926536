import * as React from "react";
import { npsScoreColor } from "../../utilities/colors";
import {
  thumb,
  tooltip,
  fill,
  track,
  range,
  draggingDiv,
} from "./NpsRange.module.scss";

export default function NpsRange({ rangeValue, setRangeValue }) {
  //fill width controls the movement of the slider. If no fill width is set, the slider will not move.

  const [fillWidth, setFillWidth] = React.useState("60%");

  //Tracks whether or not the user is dragging the slider

  const [isDragging, setIsDragging] = React.useState(false);

  //default values for slider

  const percentMin = 0;
  const valueMin = 30;
  const max = 100;

  //Sets the available dragging area to the width of the target bounds.

  function startDrag({ currentTarget, nativeEvent }) {
    let range = currentTarget;
    document.draggingRange = range;
    setRangeInputFromMouseEvent(range, nativeEvent);
  }

  //controls the movement of the slider. Triggers the dragging state to true. If there is no drag area, we return nothing.

  function moveSlider({ nativeEvent }) {
    let range = document.draggingRange;

    if (!range) return;
    setIsDragging(true);

    setRangeInputFromMouseEvent(range, nativeEvent);
  }

  //signifies the end of the drag and sets is dragging to false and clears the dragging area.

  function cancelDrag() {
    let range = document.draggingRange;
    if (!range) return;

    setIsDragging(false);
    document.draggingRange = undefined;
  }

  //Checks for touch event, identifies the range bounds, calculates the position of the mouse within the bounds to determine what to set the fill width and value.

  function setRangeInputFromMouseEvent(range, event) {
    if (window.TouchEvent && event instanceof TouchEvent) {
      event = event.targetTouches[0];
    }

    let { x: rangeX, width: rangeWidth } = range.getBoundingClientRect();
    let { clientX: mouseX } = event;

    let percent = (mouseX - rangeX) / rangeWidth;
    let newRange = max - valueMin;
    let value = percent * newRange + valueMin;

    setRangeValue(Math.min(Math.max(value.toFixed(), valueMin), max));
    setFillWidth(`${Math.min(Math.max(percent, percentMin), max) * 100}%`);
  }

  return (
    <div
      id="range"
      className={range}
      data-color={npsScoreColor(rangeValue).name}
      role="slider"
      aria-label="Find your Net Promoter Score"
      aria-valuenow={rangeValue}
      tabIndex={0}
      style={{
        touchAction: "none",
        maxWidth: "50rem",
        width: "100%",
      }}
      data-dragging={isDragging}
      onMouseUp={cancelDrag}
      onMouseLeave={cancelDrag}
      onMouseDown={startDrag}
      onMouseMove={moveSlider}
      onTouchMove={moveSlider}
      onTouchStart={startDrag}
      onTouchEnd={cancelDrag}
    >
      {/* creates a fixed, screen wide and high div that allows the user to keep dragging wherever they're at on the screen */}
      {isDragging && <div className={draggingDiv}></div>}
      <div className={track}>
        <div className={fill} style={{ width: fillWidth }}>
          <div className={thumb}>
            <h2 className={tooltip}>{rangeValue}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
